import { CurrencyCode } from "Infrastructure/Models/CurrencyCodes";

export const formatNumber = (
  value: number | null | undefined,
  currencyCode?: CurrencyCode | string | null | undefined,
  decimalPlaces = 2,
) => {
  const formatter = new Intl.NumberFormat("cs-CZ", {
    style: "decimal",
    currency: currencyCode ?? CurrencyCode.CZK,
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: 0,
  });
  return formatter.format(value ?? 0);
};
