import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { ApiCallError } from "Infrastructure/Api/ApiUtils";
import { getTrianglCalculatorElement } from "Infrastructure/Utils/trianglOptionsUtils";
import React, { type ErrorInfo, type PropsWithChildren } from "react";

type State = { error?: Error; componentStack?: string | null };
type ErrorBoundaryProps = PropsWithChildren<{ reset: () => void }>;

class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
    props.reset();
  }

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  componentDidCatch(error: Error, { componentStack }: ErrorInfo) {
    this.props.reset();
    const triangelElement = getTrianglCalculatorElement();
    if (typeof triangelElement?.errorCallback === "function") {
      triangelElement.errorCallback(error);
    }
    if (import.meta.env.MODE === "development" && error instanceof ApiCallError) {
      console.error("ApiCallError: ", error.data);
    }
    this.setState({ error, componentStack });
  }

  render() {
    return this.state.error ? (
      <ErrorDisplay componentStack={this.state.componentStack} error={this.state.error} />
    ) : (
      this.props.children
    );
  }
}

const ErrorDisplay = ({ componentStack, error }: State) => {
  if (import.meta.env.MODE === "development") {
    return (
      <div>
        <h1>Something went wrong.</h1>
        <p>{error?.message}</p>
        <p>{componentStack}</p>
      </div>
    );
  }
  return null;
};

const ErrorBoundaryWrapper = ({ children }: PropsWithChildren) => {
  const { reset } = useQueryErrorResetBoundary();
  return <ErrorBoundary reset={reset}>{children}</ErrorBoundary>;
};

export default ErrorBoundaryWrapper;
