import type { HomeCreditCallbackData, TrianglCalculatorResponse } from "types";

interface HcCalc extends HTMLElement {
  apiKey: string;
  productSetCode: string;
  price: number;
  downPayment: number;
  fixDownPayment: boolean;
  dataCalculatorBaseUrl: string;
  callback: (data: HomeCreditCallbackData) => void;
  language: string;
  debug: boolean;
  isModal?: boolean;
  hideHeader?: boolean;
}

export const appendHomeCreditCalc = (
  data: { price: number; productCode: string },
  hcCallback: (data: HomeCreditCallbackData) => void,
  retryCount = 0,
) => {
  if (typeof customElements.get(hcCalcTagName) !== "function") {
    if (retryCount > 5) {
      throw new Error("HomeCredit script could not be loaded.");
    }
    setTimeout(
      () => {
        appendHomeCreditCalc(data, hcCallback, retryCount + 1);
      },
      2 ** retryCount * 100,
    );
    return;
  }

  const wrapper = document.getElementById(homeCreditWrapperId);
  if (!wrapper) return;
  const calc = wrapper.getElementsByTagName(hcCalcTagName)?.[0] as HcCalc | undefined;

  const app = !!calc ? calc : (document.createElement(hcCalcTagName) as HcCalc);
  app.callback = hcCallback;
  app.apiKey = import.meta.env.VITE_HOMECREDIT_API_KEY;
  app.productSetCode = data.productCode;
  app.dataCalculatorBaseUrl = import.meta.env.VITE_HOMECREDIT_API_URL;
  app.price = data.price;
  app.downPayment = 0;
  app.fixDownPayment = false;
  app.language = "cs-CZ";
  app.debug = import.meta.env.VITE_HOMECREDIT_DEBUG?.toLocaleLowerCase() === "true";
  app.isModal = false;
  app.hideHeader = true;

  if (!calc) {
    wrapper.appendChild(app);
  }
};

export const loadHomeCreditAppScript = () => {
  const wrapper = document.getElementById(homeCreditWrapperId);
  if (!wrapper) return;

  const appScriptPath = new URL("/hc-calc/app.js", import.meta.url).href;
  const childNodes = wrapper.childNodes;
  let hasAppLoaderScript = false;
  for (const node of childNodes) {
    if (node.nodeName === "SCRIPT") {
      const scriptNode = node as HTMLScriptElement;
      if (scriptNode.src.includes(appScriptPath)) hasAppLoaderScript = true;
    }
  }

  if (!hasAppLoaderScript) {
    const appLoaderScript = document.createElement("script");
    appLoaderScript.src = appScriptPath;
    appLoaderScript.async = true;
    wrapper.appendChild(appLoaderScript);
  }
};

export const mapHcDataToResponse = (data: HomeCreditCallbackData): TrianglCalculatorResponse => ({
  creditAmount: data.creditAmount,
  downPayment: data.preferredDownPayment,
  period: data.preferredMonths,
  preferredInstallment: data.preferredInstallment,
  productCode: data.productCode,
});

const isStyle = (node: ChildNode | null): node is HTMLStyleElement => node?.nodeName === "STYLE";

export const loadHomeCreditOverrideStyles = (retryCount = 0) => {
  const shadowRoot = document.querySelector(hcCalcTagName)?.shadowRoot;
  if (!shadowRoot) {
    if (retryCount > 5) {
      throw new Error("HomeCredit script could not be loaded.");
    }
    setTimeout(
      () => {
        loadHomeCreditOverrideStyles(retryCount + 1);
      },
      2 ** retryCount * 100,
    );
    return;
  }
  const overrideStyleExists =
    isStyle(shadowRoot.firstChild) && shadowRoot.firstChild.innerHTML.startsWith("main.l-app--nonModal");

  if (overrideStyleExists) {
    return;
  }

  const style = document.createElement("style");
  style.innerHTML = `
  main.l-app--nonModal {
    padding: 0px;
    border-radius: 0px;
    }
    `;
  shadowRoot.prepend(style);
};

export const homeCreditWrapperId = "hc-calc-wrapper";
const hcCalcTagName = "hc-calc";
