import { Box } from "@mui/material";
import type { FinancingType } from "Infrastructure/Api/Api";
import useCalculationQuery from "Infrastructure/Hooks/useCalculationQuery";
import useDebouncedState from "Infrastructure/Hooks/useDebouncedState";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { convertToHPTPrice } from "Infrastructure/Utils/priceUtils";
import InstallmentDetail from "Shared/InstallmentDetail";
import MonthlyInstallment from "Shared/MonthlyInstallment";
import SliderInput from "Shared/SliderInput";
import { useCalcArgument } from "../Providers/CalcArgumentProvider";

type Props = {
  productCode: string;
  amount: number;
  canSelectInstallment?: boolean;
  financingType: FinancingType;
};

const InstallmentCalculator = ({ amount, canSelectInstallment, productCode, financingType }: Props) => {
  const [period, setPeriod] = useDebouncedState(72, 500);
  const [downPayment, setdownPayment] = useDebouncedState(0, 500);
  const { t } = useResource();
  const params = useCalcArgument();

  const { data } = useCalculationQuery(
    { amount, productCode, downPayment: downPayment || undefined, period: period },
    { notifyOnChangeProps: ["data"] },
  );

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <MonthlyInstallment amount={data?.paymentAmountMonthly ?? 0} />
      <SliderInput
        text={t(Resources.EnterPeriod)}
        onChange={setPeriod}
        min={6}
        max={72}
        step={1}
        defaultValue={period}
        unit={t(Resources.MonthsWithoutCount, { count: period })}
        minUnitSize="6ch"
      />
      <SliderInput
        min={0}
        max={amount - 2000 - ((amount - 2000) % 100)}
        step={100}
        disabled={amount <= 2000}
        text={t(Resources.EnterDownPayment)}
        onChange={setdownPayment}
        unit={t(Resources.CZK)}
      />
      <InstallmentDetail
        productCode={productCode}
        amount={data?.amount}
        totalAmount={data?.totalCost}
        annualInterestRate={data?.interestRateAnnual}
        apr={data?.creditCostRateAnnual}
        canSelectInstallment={canSelectInstallment}
        onSelectInstallment={() => {
          params?.callback?.({
            creditAmount: convertToHPTPrice(data?.amount ?? 0),
            downPayment: data?.downPayment ?? 0,
            period: data?.period ?? 0,
            preferredInstallment: data?.paymentAmountMonthly ?? 0,
            productCode: financingType,
          });
        }}
      />
    </Box>
  );
};

export default InstallmentCalculator;
