import { Box } from "@mui/material";
import { useSuspenseQueries } from "@tanstack/react-query";
import { CalculationType } from "Infrastructure/Api/Api";
import { calculationQuery } from "Infrastructure/Hooks/useCalculationQuery";
import { isInbankProduct } from "Infrastructure/Utils/productUtils";
import InstallmentDetaillAccordionProvider from "Providers/InstallmentDetaillAccordionProvider";
import CenteredCircularProgress from "Shared/CenteredCircularProgress";
import CustomInstallment from "Shared/CustomInstallment";
import PredefinedInstallment from "Shared/PredefinedInstallment";
import { useMemo } from "react";
import { useCalcArgument } from "../Providers/CalcArgumentProvider";
import useProducts from "Infrastructure/Hooks/useProducts";

const InbankCalc = () => {
  const params = useCalcArgument();
  const { data: products } = useProducts(params.products ?? [], {
    notifyOnChangeProps: ["data"],
    refetchOnMount: false,
  });
  const queries = useMemo(
    () =>
      (params?.products?.filter(isInbankProduct) ?? []).map((a) =>
        calculationQuery({ amount: params.price ?? 0, productCode: a }, { notifyOnChangeProps: ["data", "isLoading"] }),
      ) ?? [],
    [params],
  );
  const queriesResponse = useSuspenseQueries({ queries });
  const isLoading = queriesResponse.some((a) => a.isLoading);
  const data = useMemo(
    () =>
      queriesResponse
        .map((a) => a.data)
        .sort(
          (a, b) =>
            (products?.find((p) => p.code === a.productCode)?.priority ?? 0) -
            (products?.find((p) => p.code === b.productCode)?.priority ?? 0),
        ),
    [products, queriesResponse],
  );

  if (isLoading) return <CenteredCircularProgress />;

  return (
    <InstallmentDetaillAccordionProvider>
      <Box display="flex" flexDirection="column" gap={2}>
        {data.map((a) =>
          a?.type === CalculationType.Predefined ? (
            <PredefinedInstallment key={a.productCode} data={a} />
          ) : a?.type === CalculationType.Custom ? (
            <CustomInstallment key={a.productCode} data={a} />
          ) : null,
        )}
      </Box>
    </InstallmentDetaillAccordionProvider>
  );
};

export default InbankCalc;
