import { debounce } from "lodash-es";
import { type SetStateAction, useMemo, useState } from "react";

const useDebouncedState = <T>(
  defaultValue: T,
  debounceTime: number,
): [T, (setState: React.SetStateAction<T>) => void] => {
  const [state, setState] = useState(defaultValue);
  const debouncedSetState = useMemo(
    () =>
      debounce((value: SetStateAction<T>) => {
        setState(value);
      }, debounceTime),
    [debounceTime],
  );

  return [state, debouncedSetState];
};

export default useDebouncedState;
