import { Box, Grid, styled } from "@mui/material";
import InbankCalc from "Calculators/InbankCalc";
import useProducts from "Infrastructure/Hooks/useProducts";
import { getUniqueCalculatorProviders, isHomeCreditProduct, isInbankProduct } from "Infrastructure/Utils/productUtils";
import { useCalcArgument } from "Providers/CalcArgumentProvider";
import CalcTypeButton from "Shared/Buttons/CalcTypeButton";
import CenteredCircularProgress from "Shared/CenteredCircularProgress";
import HomeCreditLogo from "Shared/logos/HomeCreditLogo";
import InbankLogo from "Shared/logos/InbankLogo";
import React, { useEffect, useMemo } from "react";
import { Suspense, useState } from "react";
import type { CalculatorProviderType } from "types";
import HomeCreditCalc from "Calculators/HomeCreditCalc/index";

const OutterBox = styled(Box)(({ theme }) => ({
  gap: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
}));

const Calculators = () => {
  const params = useCalcArgument();
  const [selectedTab, setSelectedTab] = useState<CalculatorProviderType>();
  const { data: products, isLoading } = useProducts(params.products ?? [], {
    notifyOnChangeProps: ["data", "isLoading"],
  });

  const lowerPriorityProvider = useMemo(() => {
    const inbankPriority = Math.min(...(products?.filter((p) => isInbankProduct(p.code)).map((p) => p.priority) ?? []));
    const homeCreditPriority = Math.min(
      ...(products?.filter((p) => isHomeCreditProduct(p.code)).map((p) => p.priority) ?? []),
    );
    const provider: CalculatorProviderType = inbankPriority <= homeCreditPriority ? "Inbank" : "HomeCredit";
    return provider;
  }, [products]);

  const displayedCalculators = useMemo(() => getUniqueCalculatorProviders(params.products), [params.products]);
  useEffect(() => {
    if (!!selectedTab || isLoading) return;

    if (displayedCalculators.includes(lowerPriorityProvider)) {
      setSelectedTab(lowerPriorityProvider);
    } else {
      setSelectedTab(displayedCalculators[0]);
    }
  }, [lowerPriorityProvider, selectedTab, isLoading, displayedCalculators]);

  if (isLoading) return <CenteredCircularProgress />;
  if (displayedCalculators.length === 0) throw new Error("No calculator providers found!");

  return (
    <OutterBox>
      {displayedCalculators.length > 1 && (
        <>
          <Grid container gap={1.2} alignItems="center">
            {displayedCalculators.includes("Inbank") && (
              <Grid item order={lowerPriorityProvider === "Inbank" ? 0 : 1}>
                <CalcTypeButton
                  logo={<InbankLogo />}
                  active={selectedTab === "Inbank"}
                  onClick={() => setSelectedTab("Inbank")}
                />
              </Grid>
            )}
            {displayedCalculators.includes("HomeCredit") && (
              <Grid item order={lowerPriorityProvider === "HomeCredit" ? 0 : 1}>
                <CalcTypeButton
                  logo={<HomeCreditLogo />}
                  active={selectedTab === "HomeCredit"}
                  onClick={() => setSelectedTab("HomeCredit")}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}

      <Suspense fallback={<CenteredCircularProgress />}>
        {displayedCalculators.includes("Inbank") && selectedTab === "Inbank" && <InbankCalc />}
        {displayedCalculators.includes("HomeCredit") && selectedTab === "HomeCredit" && <HomeCreditCalc />}
      </Suspense>
    </OutterBox>
  );
};

export default Calculators;
