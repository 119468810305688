import { createContext, useContext, useState } from "react";

type InstallmentDetaillAccordionContextType = {
  expandedDetailProductCode?: string;
  setExpandedDetailProductCode: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const InstallmentDetaillAccordionContext = createContext<InstallmentDetaillAccordionContextType>(null!);

const InstallmentDetaillAccordionProvider = ({ children }: React.PropsWithChildren) => {
  const [state, setState] = useState<string>();

  return (
    <InstallmentDetaillAccordionContext.Provider
      value={{ setExpandedDetailProductCode: setState, expandedDetailProductCode: state }}
    >
      {children}
    </InstallmentDetaillAccordionContext.Provider>
  );
};

export default InstallmentDetaillAccordionProvider;

export const useInstallmentDetaillAccordion = (productCode: string): [boolean, () => void] => {
  const ctx = useContext(InstallmentDetaillAccordionContext);
  return [
    ctx.expandedDetailProductCode === productCode,
    () => ctx.setExpandedDetailProductCode(ctx.expandedDetailProductCode === productCode ? undefined : productCode),
  ];
};
