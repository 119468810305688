import { Box, Button, Collapse, Typography } from "@mui/material";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { formatCurrency } from "Infrastructure/Utils/CurrencyUtils";
import { convertAndRoundInterestRate } from "Infrastructure/Utils/priceUtils";
import { useInstallmentDetaillAccordion } from "Providers/InstallmentDetaillAccordionProvider";

type Props = {
  productCode: string;
  amount?: number | null;
  totalAmount?: number | null;
  annualInterestRate?: number | null;
  apr?: number | null;
  canSelectInstallment?: boolean;
  onSelectInstallment?: () => void;
  disabledSelectInstallment?: boolean;
};

const InstallmentDetail = ({
  annualInterestRate,
  apr,
  amount: price,
  totalAmount,
  canSelectInstallment,
  onSelectInstallment,
  disabledSelectInstallment,
  productCode,
}: Props) => {
  const { t } = useResource();
  const [showDetail, setShowDetail] = useInstallmentDetaillAccordion(productCode);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" flexDirection="row" alignItems="end">
        <Box
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={(_) => setShowDetail()}
          color={(a) => a.palette.grey[500]}
          fontSize={13}
        >
          {showDetail ? t(Resources.HideInstallmentDetail) : t(Resources.ShowInstallmentDetail)}
        </Box>
        {canSelectInstallment && (
          <Button
            variant="contained"
            color="primary"
            onClick={onSelectInstallment}
            disabled={disabledSelectInstallment}
          >
            {t(Resources.SelectInstallment)}
          </Button>
        )}
      </Box>

      <Collapse in={showDetail}>
        <Box gap={1} display="flex" flexDirection="column" pt={3.2}>
          <Box display="flex" justifyContent="space-between">
            <Typography>{t(Resources.CreditAmount)}</Typography>
            <Typography fontWeight={700}>{`${formatCurrency(price)}`}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography>{t(Resources.annualInterestRate)}</Typography>
            <Typography fontWeight={700}>{convertAndRoundInterestRate(annualInterestRate ?? 0)} %</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography>{t(Resources.APN)}</Typography>
            <Typography fontWeight={700}>{convertAndRoundInterestRate(apr ?? 0)} %</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography>{t(Resources.TotalAmountPaid)}</Typography>
            <Typography fontWeight={700}>{`${formatCurrency(totalAmount ?? 0)}`}</Typography>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default InstallmentDetail;
