export type ResourceDictionary = typeof CzechResourcesDictionary;

export const CzechResourcesDictionary = {
  Close: "Zavřít",
  InstallmentCalculators: "Splátkové kalkulačky",
  InstallmentCalculator: "Splátková kalkulačka",
  CustomInstallments: "Splátky na míru",
  Month: "měsíc",
  Months: "{{count}} měsíc",
  Months_one: "{{count}} měsíc",
  Months_two: "{{count}} měsíce",
  Months_few: "{{count}} měsíce",
  Months_other: "{{count}} měsíců",
  SelectMonthlyInstallment: "Zvolte měsíční splátku",
  EnterDownPayment: "Kolik zaplatíte předem",
  MonthlyInstallment: "Měsíční splátka",
  MonthsWithoutCount: "měsíc ",
  MonthsWithoutCount_one: "měsíc",
  MonthsWithoutCount_two: "měsíce",
  MonthsWithoutCount_few: "měsíce",
  MonthsWithoutCount_other: "měsíců",
  CreditAmount: "Výše úvěru",
  TotalAmountPaid: "Celkově zaplacená částka",
  annualInterestRate: "Roční úroková sazba",
  APN: "RPSN",
  CZK: "Kč",
  LegalText1:
    "Výpočet RPSN byl stanoven k datu provedení kalkulace s tím, že ve výpočtu RPSN je zohledněn přesný počet dnů mezi datem kalkulace, datem první splátky a dalšími jednotlivými splátkami, přičemž datum splatnosti měsíčních splátek je 15. dne v měsíci, počínaje měsícem následujícím po podpisu smlouvy.",
  LegalText2:
    "Nabídka splátek není závaznou nabídkou na uzavření smlouvy a je pouze orientační; finální parametry budou stanoveny ve smlouvě, pokud se společnost k uzavření smlouvy rozhodne přistoupit.",
  DetailInfoText: "Kalkulačka je orientační. Způsob platby na splátky si můžete nastavit v košíku.",
  CalculateInstallments: "Chci si spočítat splátky",
  SelectInstallment: "Vybrat splátky",
  ShowInstallmentDetail: "Zobrazit detail splátek",
  HideInstallmentDetail: "Skrýt detail splátek",
  EnterPeriod: "Zadejte dobu splácení",
};
