import { ThemeProvider } from "@emotion/react";
import { ScopedCssBaseline } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Calculators from "Calculators";
import { getTheme } from "Infrastructure/Themes/Themes";
import i18n from "Infrastructure/Translations/Resources";
import ErrorBoundary from "Shared/ErrorBoundary";
import { I18nextProvider } from "react-i18next";
import CalcArgumentProvider from "./Providers/CalcArgumentProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      throwOnError: true,
    },
    queries: {
      throwOnError: true,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <ErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <CalcArgumentProvider>
            <ThemeProvider theme={getTheme()}>
              <ScopedCssBaseline>
                <Calculators />
              </ScopedCssBaseline>
            </ThemeProvider>
          </CalcArgumentProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </ErrorBoundary>
  );
}

export default App;
