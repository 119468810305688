import type { CalculatorProviderType } from "types";

export function getUniqueCalculatorProviders(products: string[] = []) {
  return products.reduce((acc, curr) => {
    if (isInbankProduct(curr) && !acc.includes("Inbank")) {
      acc.push("Inbank");
    } else if (isHomeCreditProduct(curr) && !acc.includes("HomeCredit")) {
      acc.push("HomeCredit");
    }
    return acc;
  }, [] as CalculatorProviderType[]);
}

export function isHomeCreditProduct(product: string) {
  return product.toLowerCase().includes("onl");
}

export const isInbankProduct = (product: string) => {
  return product.toLowerCase().includes("inbank");
};
