import { getTrianglCalculatorOptions } from "Infrastructure/Utils/trianglOptionsUtils";
import { createContext, useContext, useMemo } from "react";
import type { TrianglCalculatorOptions } from "../types";

const CalcArgumentContext = createContext<TrianglCalculatorOptions>(null!);

const CalcArgumentProvider = ({ children }: React.PropsWithChildren) => {
  const calcArguments = useMemo(getTrianglCalculatorOptions, []);

  return <CalcArgumentContext.Provider value={calcArguments}>{children}</CalcArgumentContext.Provider>;
};

export default CalcArgumentProvider;

export const useCalcArgument = () => useContext(CalcArgumentContext);
