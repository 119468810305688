import * as React from "react";
const ExpandIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99866 5L8 2.5V0.103875L3.99866 2.61986L0 0V2.5L3.99866 5Z"
      fill="#001744"
    />
  </svg>
);
export default ExpandIcon;
