import { Box, Stack, Typography } from "@mui/material";
import type { CalculationResponse } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { convertToHPTPrice } from "Infrastructure/Utils/priceUtils";
import InstallmentDetail from "Shared/InstallmentDetail";
import InstallmentTags from "Shared/InstallmentTags";
import { useCalcArgument } from "../Providers/CalcArgumentProvider";

type Props = {
  data: CalculationResponse;
};

const PredefinedInstallment = ({
  data: {
    amount,
    paymentAmountMonthly,
    period,
    name: text,
    tags,
    interestRateAnnual,
    creditCostRateAnnual,
    downPayment,
    totalCost: totalAmount,
    financingType,
    productCode,
  },
}: Props) => {
  const { t } = useResource();
  const params = useCalcArgument();

  return (
    <Stack border={(t) => `1px solid ${t.palette.grey[300]}`} borderRadius="3px" p={1} gap={1.2}>
      <Stack direction="row" justifyContent="space-between" gap={1}>
        <Stack flexDirection="row" color={(a) => a.palette.error.main} gap={1} flexWrap="wrap" flex={1}>
          <Typography noWrap>
            <b>
              {paymentAmountMonthly} {t(Resources.CZK)}
            </b>
            {" x "} {t(Resources.Months, { count: period })}
          </Typography>

          <InstallmentTags tags={tags.map((a) => ({ color: "error", label: a })) ?? []} />
        </Stack>

        <Typography fontWeight={700} color={(a) => a.palette.primary.dark} textAlign="right">
          {text}
        </Typography>
      </Stack>
      <InstallmentDetail
        productCode={productCode}
        amount={amount}
        totalAmount={totalAmount}
        annualInterestRate={interestRateAnnual}
        apr={creditCostRateAnnual}
        canSelectInstallment={params?.type === "cart"}
        onSelectInstallment={() => {
          params?.callback?.({
            creditAmount: convertToHPTPrice(amount),
            downPayment: downPayment ?? 0,
            period,
            preferredInstallment: paymentAmountMonthly ?? 0,
            productCode: financingType,
          });
        }}
      />
    </Stack>
  );
};

export default PredefinedInstallment;
