import type { Theme } from "@emotion/react";
import { type ThemeOptions, createTheme } from "@mui/material/styles";

export type ColorsType = typeof colors;

const colors = {
  PrimaryMain: "#00AFD8",
  PrimaryDark: "#001744",
  PrimaryLight: "#4CADD4",
  PrimaryText: "#ffffff",
  Body: "#ffffff",
  Red: "#E42221",
  Warning: "#F7B500",
  WarningLight: "#FACC15",
  Green: "#70f0a6",
  Text: "#001744",
  TextLight: "#64748B",
  TextDisabled: "#2f2c2766",
  Black: "#000000",
  White: "#ffffff",
  Gray: "#D7D7D7",
  Border: "#CBD5E1",
  DarkGrey: "#334155",
  Validation: "#FF6690",
  Placeholder: "#8E98A7",
  FieldBackground: "#FFFFFF",
  ContentWrapperBorder: "#E2E8F0",
  RadioBtnDisabled: "#EBEEF1",
  WorkflowIcon: "#DFE5F1",
} as const;

const theme = (color: ColorsType): Theme => {
  const theme: ThemeOptions = {
    palette: {
      primary: {
        light: color.PrimaryMain,
        main: color.PrimaryLight,
        dark: color.PrimaryDark,
        contrastText: color.PrimaryText,
      },
      secondary: {
        light: color.PrimaryMain,
        main: color.PrimaryMain,
        dark: color.PrimaryLight,
        contrastText: color.PrimaryText,
      },
      text: {
        primary: color.Text,
        secondary: color.TextLight,
        disabled: color.TextDisabled,
      },
      error: {
        main: color.Red,
        dark: color.Red,
        contrastText: color.White,
      },
      success: {
        main: color.Green,
      },
      info: {
        main: color.PrimaryMain,
        light: color.PrimaryMain,
        dark: color.PrimaryMain,
        contrastText: color.PrimaryText,
      },
      background: {
        default: color.Body,
      },
      common: {
        black: color.Black,
        white: color.White,
      },
      warning: {
        main: color.Warning,
        light: color.WarningLight,
      },
      divider: color.Border,
      grey: {
        "300": color.Gray,
        "400": "#F9F9F9",
        "500": "#999999",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 688,
        lg: 768,
        xl: 1025,
      },
    },
    spacing: 10,
    shape: {
      borderRadius: 4,
    },
    typography: {
      fontSize: 14,
      fontFamily: "Open Sans",
      body1: {
        fontSize: 15,
        fontWeight: 400,
        letterSpacing: "0.2px",
      },
      body2: {
        color: color.Text,
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: "0.2px",
      },
      h1: {
        color: color.Text,
        fontWeight: 700,
        fontSize: 24,
        lineHeight: 1.2,
      },
      h2: {
        color: color.Text,
        fontWeight: 700,
        fontSize: 18,
        lineHeight: 1.4,
        letterSpacing: "0.2px",
      },
      h3: {
        color: color.Text,
        fontWeight: 700,
        fontSize: 16,
        lineHeight: 1.4,
        letterSpacing: "0.2px",
      },
      h4: {
        color: color.Text,
        fontWeight: 700,
        fontSize: 24,
      },
      h5: {
        color: color.Text,
        fontWeight: 700,
        fontSize: 20,
      },
      subtitle1: {
        fontWeight: 400,
        color: color.Text,
        fontSize: 16,
        lineHeight: 1.4,
        letterSpacing: "0.2px",
      },
      subtitle2: {
        fontWeight: 400,
        color: color.TextLight,
        fontSize: 14,
        fontStyle: "italic",
        lineHeight: 1.4,
        letterSpacing: "0.2px",
      },
      button: {
        fontSize: 16,
        fontWeight: 500,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            color: color.Text,
            fontWeight: 400,
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: color.TextLight,
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar": {
              width: "9px",
              height: "7px",
              background: color.Gray,
              borderRadius: "4px",
            },
            "& *::-webkit-scrollbar-thumb": {
              backgroundColor: color.TextLight,
              borderRadius: "4px",
            },
            "& *::-webkit-scrollbar": {
              width: "7px",
              height: "7px",
              background: color.Gray,
              borderRadius: "4px",
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "0 2px 6px 0 rgba(215, 215, 215, 0.5)",
            "& .MuiPickersBasePicker-container .MuiPickerDTTabs-tabs .MuiButtonBase-root": {
              color: color.White,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            borderRadius: "0px",
            boxShadow: "none",
            ":hover": {
              boxShadow: "none",
            },
          },
          containedPrimary: {
            backgroundColor: color.PrimaryLight,
            ":hover": {
              backgroundColor: "#3B8DD4",
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: color.ContentWrapperBorder,
          },
        },
      },

      MuiAccordion: {
        styleOverrides: {
          root: {
            borderRadius: "3px",
            border: `1px solid ${color.Gray}`,
            boxShadow: "none",
            "&.Mui-expanded": {
              margin: "0px",
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: "0px 10px",
            minHeight: "unset",
            "&.Mui-expanded": {
              minHeight: "unset",
            },
          },
          content: {
            "&.Mui-expanded": {
              margin: "10px 0px",
            },
            margin: "10px 0px",
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          thumb: {
            color: color.PrimaryDark,
            "&.Mui-disabled": {
              color: color.Gray,
            },
          },
          rail: {
            opacity: 1,
            color: color.Gray,
          },
          track: {
            color: color.PrimaryMain,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: color.FieldBackground,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            padding: "8px 12px",
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: "0 10px 10px 10px",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: "13px",
          },
        },
      },
      MuiChip: {
        defaultProps: {
          size: "small",
        },
        styleOverrides: {
          root: {
            borderRadius: "0px",
            fontWeight: 700,
          },
          sizeSmall: {
            fontSize: "12px",
          },
        },
      },
    },
  };

  const customVariables = {
    colors: {
      red: color.Red,
      border: color.Border,
      gray: color.Gray,
      validation: color.Validation,
      darkGrey: color.DarkGrey,
      placeholder: color.Placeholder,
      fieldBackground: color.FieldBackground,
      primaryMainTransparent: `${color.PrimaryMain}1f`,
      contentWrapperBorder: color.ContentWrapperBorder,
      radioBtnDisabled: color.RadioBtnDisabled,
      workflowIcon: color.WorkflowIcon,
    },
    shadow: {
      primary: "none",
      primaryHover: "0px 0px 15px 0px rgba(0, 0, 0, 0.15) inset",
    },
    borderRadius: "12px",
    vh: (val: number) => {
      return `calc(var(--vh, 1vh) * ${val})`;
    },
  };

  return createTheme(Object.assign(theme, customVariables)) as Theme;
};

// TODO enum;
export const getTheme = () => theme(colors);
