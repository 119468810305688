import { setupClient } from "Infrastructure/Api/Api";
import { convertFromHPTPrice } from "Infrastructure/Utils/priceUtils";
import { type TrianglCalculatorOptions, trianglCalculatorOptionsSchema } from "../../types";

type TrianglCalculator = HTMLElement & {
  errorCallback: ((error?: Error) => void) | null;
  callback: (() => void) | null;
  apiKey: string;
  type: string;
  products: string[];
  price: number;
  selectedProduct?: {
    productCode: string;
    downPayment: number;
    period: number;
  };
};

export function getTrianglCalculatorElement() {
  const containerElement = document.getElementsByTagName("triangl-calculator")?.[0];
  return containerElement as TrianglCalculator;
}

const isTrianglCalculatorOptions = (obj: unknown): obj is TrianglCalculatorOptions =>
  trianglCalculatorOptionsSchema.safeParse(obj).success;

export function getTrianglCalculatorOptions(): TrianglCalculatorOptions {
  const containerElement = getTrianglCalculatorElement();
  const trianglCalcArgs = {
    apiKey: containerElement.apiKey,
    price: containerElement.price,
    products: containerElement.products,
    selectedProduct: {
      productCode: containerElement.selectedProduct?.productCode,
      downPayment: containerElement.selectedProduct?.downPayment,
      period: containerElement.selectedProduct?.period,
    },
    type: containerElement.type,
    callback: containerElement.callback,
    errrorCallback: containerElement.errorCallback,
  };

  if (isTrianglCalculatorOptions(trianglCalcArgs)) {
    setupClient({
      apiUrl: import.meta.env.VITE_API_URL,
      jwtKey: undefined,
      requestMiddlewares: [
        {
          name: "addApiKey",
          fn: (args) => {
            args.options.headers.set("X-Api-Key", trianglCalcArgs.apiKey ?? "");
            return args;
          },
        },
      ],
    });
    return {
      ...trianglCalcArgs,
      price: convertFromHPTPrice(trianglCalcArgs.price ?? 0),
    };
  }
  throw new Error("Unable to parse triangl calculator options.");
}
