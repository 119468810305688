import * as Sentry from "@sentry/react";

if (import.meta.env.VITE_SENTRY_IS_ENABLED === "true") {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.replayIntegration(), Sentry.dedupeIntegration(), Sentry.httpContextIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [new RegExp(`${import.meta.env.VITE_API_URL}/*`)],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    autoSessionTracking: false,
    environment: import.meta.env.VITE_VERSION,
  });
}
