import { Chip, type ChipProps, Stack } from "@mui/material";

type Props = {
  tags?: InstallmentTag[];
};

export type InstallmentTag = {
  color: ChipProps["color"];
  label: string;
};

const InstallmentTags = (props: Props) => {
  if (!props.tags) return null;

  return (
    <Stack flexDirection="row" gap={1} flexWrap="wrap">
      {props.tags.map((tag, index) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        <Chip key={index} label={tag.label} color={tag.color} />
      ))}
    </Stack>
  );
};

export default InstallmentTags;
