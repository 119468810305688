import {
  CzechResourcesDictionary,
  type ResourceDictionary,
} from "Infrastructure/Translations/CzechResourcesDictionary";
import i18next from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import { type ObjPathProxy, createProxy, getPath } from "ts-object-path";

export const Resources = createProxy<ResourceDictionary>();
export function getResourcePath(proxy: ObjPathProxy<ResourceDictionary, string>): string {
  const path = getPath(proxy);

  if (!path || path.length === 0) return "";
  if (path.length === 1) return path[0].toString();
  return `${path[0].toString()}:${path.slice(1).join(".")}`;
}

export function useResource() {
  const { t: i18Translation } = useTranslation();

  return {
    t: (resourcePath: ObjPathProxy<ResourceDictionary, string>, options?: any) =>
      i18Translation(getResourcePath(resourcePath), options) as unknown as string,
  };
}

export const resources = {
  cs: {
    translation: CzechResourcesDictionary,
  },
} as const;

i18next.use(initReactI18next).init({
  lng: "cs",
  resources,
});

export default i18next;
