import { useQuery } from "@tanstack/react-query";
import { postProductsSearch } from "Infrastructure/Api/Api";
import { type QueryBasicOptions, processResponse } from "Infrastructure/Api/ApiUtils";

const useProducts = (productsCodes: string[], options?: QueryBasicOptions) => {
  return useQuery({
    queryKey: ["products", [...productsCodes].sort()],
    queryFn: processResponse(() => postProductsSearch({ codes: productsCodes })),
    ...options,
  });
};

export default useProducts;
