import type { UseQueryOptions } from "@tanstack/react-query";
import type { ErrorResponse, FetchResponse, FetchResponseOfError } from "Infrastructure/Api/Api";

type BaseResponse<TData = unknown> = FetchResponse<TData, number> | ErrorResponse;

type SuccessResponse<TResponse extends BaseResponse> = Extract<TResponse, { status: 200 | 201 | 202 | 204 }>;

type SuccessResponseData<TResponse extends BaseResponse> = SuccessResponse<TResponse>["data"];

export class ApiCallError extends Error {
  data: FetchResponseOfError;

  constructor(data: FetchResponseOfError) {
    super();
    this.data = data;
    this.message = getMessageFromErrorResponse(data.error);
  }
}

export const isFetchResponseOfError = (obj: any): obj is FetchResponseOfError =>
  !!obj.data && typeof obj.status === "number";

function getMessageFromErrorResponse(response: any) {
  let errorMessage: string | undefined;
  let responseError = response;
  if (responseError instanceof ApiCallError) {
    responseError = responseError.data;
  }

  if (isFetchResponseOfError(responseError)) {
    responseError = responseError.data;
  }

  //if has property title
  if (responseError?.title?.length > 0) {
    return responseError.title;
  }

  if (responseError instanceof Error) {
    errorMessage = responseError.message;
  }

  return errorMessage ?? "Unknown error";
}

/**
 *  Process response and throw error if status code is not 2xx
 * @param request request function
 * @returns Processed response
 */
export const processResponse =
  <TArgs, TResponse extends BaseResponse>(request: (args: TArgs, headers?: Headers) => Promise<TResponse>) =>
  async (args: TArgs): Promise<SuccessResponseData<TResponse>> => {
    const response = await request(args);
    return processResponseStatusCode(response);
  };

const valid400StatusCodes = [400, 404, 424];

function processResponseStatusCode<TResponse extends BaseResponse>(
  response: TResponse,
): SuccessResponseData<TResponse> {
  if ((response?.status >= 400 || response?.status === 0) && !valid400StatusCodes.includes(response?.status)) {
    throw new ApiCallError(response as FetchResponseOfError);
  }

  return response?.data;
}

export type QueryBasicOptions = {
  _defaulted?: UseQueryOptions["_defaulted"];
  _optimisticResults?: UseQueryOptions["_optimisticResults"];
  cacheTime?: UseQueryOptions["gcTime"];
  enabled?: boolean;
  initialDataUpdatedAt?: UseQueryOptions["initialDataUpdatedAt"];
  meta?: UseQueryOptions["meta"];
  networkMode?: UseQueryOptions["networkMode"];
  notifyOnChangeProps?: UseQueryOptions["notifyOnChangeProps"];
  refetchIntervalInBackground?: UseQueryOptions["refetchIntervalInBackground"];
  retryOnMount?: UseQueryOptions["retryOnMount"];
  staleTime?: number;
  refetchInterval?: number | false;
  refetchOnMount?: boolean | "always";
  refetchOnReconnect?: boolean | "always";
  refetchOnWindowFocus?: boolean | "always";
  retry?: boolean | number;
  retryDelay?: number;
  structuralSharing?: boolean;
  useErrorBoundary?: boolean;
};
