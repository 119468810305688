import { type UndefinedInitialDataOptions, useQuery } from "@tanstack/react-query";
import { type CalculationRequest, type CalculationResponse, postCalculation } from "Infrastructure/Api/Api";
import { type QueryBasicOptions, processResponse } from "Infrastructure/Api/ApiUtils";

export const createCalculationQueryKey = (requestContract: CalculationRequest) => ["calculation", requestContract];

export const calculationQuery = (
  requestContract: CalculationRequest,
  options?: QueryBasicOptions,
): UndefinedInitialDataOptions<CalculationResponse, Error, CalculationResponse, (string | CalculationRequest)[]> => ({
  queryKey: createCalculationQueryKey(requestContract),
  queryFn: processResponse(() => postCalculation(requestContract)),
  placeholderData: (prev) => prev,
  ...options,
});

const useCalculationQuery = (requestContract: CalculationRequest, options?: QueryBasicOptions) =>
  useQuery(calculationQuery(requestContract, options));

export default useCalculationQuery;
