export function convertToHPTPrice(price: number) {
  return price * 100;
}

export function convertFromHPTPrice(price: number) {
  return price / 100;
}

export function convertToHCPrice(price: number) {
  return price * 100;
}

export function convertFromHCPrice(price: number) {
  return price / 100;
}

export function convertAndRoundInterestRate(value: number) {
  const valueInPercent = value * 100;
  return Math.round(valueInPercent * 100) / 100;
}
