import {
  appendHomeCreditCalc,
  homeCreditWrapperId,
  loadHomeCreditAppScript,
  loadHomeCreditOverrideStyles,
  mapHcDataToResponse,
} from "Calculators/HomeCreditCalc/helpers";
import { convertToHCPrice } from "Infrastructure/Utils/priceUtils";
import { isHomeCreditProduct } from "Infrastructure/Utils/productUtils";
import { useEffect } from "react";
import { useCalcArgument } from "../../Providers/CalcArgumentProvider";

const HomeCreditCalc = () => {
  const calcParams = useCalcArgument();
  useEffect(() => {
    const product = calcParams?.products?.find(isHomeCreditProduct);
    if (!product) throw new Error("No HomeCredit product found");

    loadHomeCreditAppScript();
    appendHomeCreditCalc(
      {
        price: convertToHCPrice(calcParams!.price ?? 0),
        productCode: product,
      },
      (data) => {
        calcParams?.callback?.(mapHcDataToResponse(data));
      },
    );
    loadHomeCreditOverrideStyles();
  }, [calcParams]);

  return (
    <div id={homeCreditWrapperId}>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600&display=swap" rel="stylesheet" />
    </div>
  );
};

export default HomeCreditCalc;
