import { Box, Stack, Typography } from "@mui/material";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { formatNumber } from "Infrastructure/Utils/NumberUtils";

type Props = {
  amount: number;
};

const MonthlyInstallment = ({ amount }: Props) => {
  const { t } = useResource();
  return (
    <Box bgcolor={(a) => a.palette.grey[400]} py={1.2}>
      <Stack justifyContent="end" alignItems="center" flexDirection="row" gap={1} px={2}>
        <Typography fontWeight={700}>{t(Resources.MonthlyInstallment)}</Typography>
        <Box
          sx={{
            margin: (a) => a.spacing(1),
          }}
          px={1.2}
          py={0.8}
          borderRadius={1}
          minWidth={91}
          textAlign="end"
          border={(a) => `1px solid ${a.palette.grey[300]}`}
          color={(a) => a.palette.primary.dark}
        >
          {formatNumber(amount)}
        </Box>
        <Typography>{t(Resources.CZK)}</Typography>
      </Stack>
    </Box>
  );
};

export default MonthlyInstallment;
