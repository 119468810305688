import React from "react";

const RadioUncheckedIcon = (props: React.SVGProps<SVGSVGElement> & { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18 * (props.size ?? 1)}
    height={18 * (props.size ?? 1)}
    viewBox="0 0 18 18"
    {...props}
  >
    <title>Unchecked</title>
    <circle cx="9" cy="9" r="8.5" fill="white" stroke="#808BA1" />
  </svg>
);

export default RadioUncheckedIcon;
