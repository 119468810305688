import { Box } from "@mui/material";
import Radio from "Shared/Radio";

type Props = { active?: boolean; logo: JSX.Element; onClick?: () => void };

const CalcTypeButton = ({ active, logo, onClick }: Props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={onClick}
      border={(t) => (active ? `2px solid ${t.palette.primary.main}` : `1px solid ${t.palette.grey[300]}`)}
      gap={0.8}
      pl={1.3}
      pr={1.4}
      py={0.8}
      sx={{ cursor: "pointer" }}
    >
      <Radio checked={active} />
      {logo}
    </Box>
  );
};

export default CalcTypeButton;
