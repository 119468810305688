import { getTrianglCalculatorElement } from "Infrastructure/Utils/trianglOptionsUtils.ts";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./sentry.ts";

function mountApp(retryCount = 0) {
  const containerElement = getTrianglCalculatorElement();
  if (containerElement) {
    ReactDOM.createRoot(containerElement).render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );
  } else if (retryCount < 5) {
    setTimeout(() => mountApp(retryCount + 1), 2 ** retryCount * 100);
  } else {
    console.error("Triangl calculator element not found!");
  }
}

window.showTrianglCalculator = () => mountApp();
mountApp();
