import { Box, Slider, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";

type Props = {
  onChange?: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  text: string;
  unit: string;
  defaultValue?: number;
  minUnitSize?: string;
  disabled?: boolean;
};

const SliderInput = ({
  text,
  max = 1000,
  min = 0,
  step = 100,
  onChange,
  unit,
  defaultValue,
  minUnitSize,
  disabled,
}: Props) => {
  const [value, setValue] = useState<number>(defaultValue ?? min);

  return (
    <Stack bgcolor={(a) => a.palette.grey[400]} pt={1.2} px={1.2} flexDirection="column" borderRadius={1}>
      <Stack justifyContent="end" flexDirection="row" alignItems="center" gap={1}>
        <Typography fontSize={13} fontWeight={700}>
          {text}
        </Typography>
        <TextField
          sx={{ width: 91 }}
          inputProps={{ style: { textAlign: "end", padding: "14px" } }}
          value={value}
          disabled={disabled}
          onChange={(a) => {
            let number = Number(a.target.value);
            if (Number.isNaN(number)) {
              return;
            }

            if (number < min) {
              number = min;
            }
            if (number > max) {
              number = max;
            }

            setValue(number);
            onChange?.(Math.round(number / step) * step);
          }}
          onBlur={() => {
            if (value % step !== 0) {
              setValue(Math.round(value / step) * step);
            }
          }}
        />
        <Box minWidth={minUnitSize}>
          <Typography>{unit}</Typography>
        </Box>
      </Stack>

      <Box pt={0.5}>
        <Slider
          value={value}
          min={min}
          max={max}
          disabled={disabled}
          onChange={(_, b) => {
            const value = b as number;
            setValue(value);
            onChange?.(value);
          }}
          step={step}
        />
      </Box>
    </Stack>
  );
};

export default SliderInput;
