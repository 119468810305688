import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import type { CalculationResponse } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import ExpandIcon from "Shared/Icons/ExpandIcon";
import InstallmentCalculator from "Shared/InstallmentCalculator";
import { useCalcArgument } from "../Providers/CalcArgumentProvider";

type Props = {
  data: CalculationResponse;
};

const CustomInstallmentAccordion = ({ data: { financingType, productCode } }: Props) => {
  const { t } = useResource();
  const calcParams = useCalcArgument();
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Box display="flex" width="100%" pr={1} justifyContent="space-between">
          <Typography fontWeight={700} color={(a) => a.palette.error.main}>
            {t(Resources.CalculateInstallments)}
          </Typography>
          <Typography fontWeight={700} color={(a) => a.palette.primary.dark}>
            {t(Resources.CustomInstallments)}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <InstallmentCalculator
          amount={calcParams!.price ?? 0}
          canSelectInstallment={calcParams?.type === "cart"}
          productCode={productCode}
          financingType={financingType}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomInstallmentAccordion;
