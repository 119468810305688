import { type TypeOf, array, function as function_, literal, number, object, string, union, void as void_ } from "zod";

export const homeCreditCallbackDataSchema = object({
  annualInterestRate: number(),
  annualPercentageRate: number(),
  creditAmount: number(),
  creditTotalRepay: number(),
  legalLine: string(),
  preferredDownPayment: number(),
  preferredInstallment: number(),
  preferredMonths: number(),
  productCode: string(),
});

export type HomeCreditCallbackData = TypeOf<typeof homeCreditCallbackDataSchema>;

export const calcTypeSchema = union([literal("detail"), literal("cart")]);

export type CalcType = TypeOf<typeof calcTypeSchema>;

// options for Triangl calculator - render options for Triangl calculator
export type TrianglCalculatorOptions = TypeOf<typeof trianglCalculatorOptionsSchema>;

export const trianglCalculatorSelectedProductSchema = object({
  productCode: string().optional(),
  downPayment: number().optional(), //akontace  // format 5 000,- ==> 500000
  period: number().optional(), // in months
});

export const trianglCalculatorResponseSchema = trianglCalculatorSelectedProductSchema.extend({
  creditAmount: number(), //celková výše úvěru (měla by odpovídat ceně zboží / košíku)
  preferredInstallment: number(), // preferovaná výše splátky
});

const errorSchema = object({
  error: string(),
  message: string(),
  stack: string().optional(),
});

export const callbackFunctionSchema = function_().args(trianglCalculatorResponseSchema).returns(void_());
export const errorCallbackFunctionSchema = function_().args(errorSchema.optional()).returns(void_());

export const trianglCalculatorOptionsSchema = object({
  callback: callbackFunctionSchema.optional(),
  errorCallback: errorCallbackFunctionSchema.optional(),
  apiKey: string().optional(),
  type: calcTypeSchema,
  products: array(string()).optional(),
  price: number().optional(),
});

export type CallbackFunction = TypeOf<typeof callbackFunctionSchema>;
export type TrianglCalculatorSelectedProduct = TypeOf<typeof trianglCalculatorSelectedProductSchema>;

// response from Triangl calculator
export type TrianglCalculatorResponse = TypeOf<typeof trianglCalculatorResponseSchema>;

export type CalculatorProviderType = "Inbank" | "HomeCredit";
